<template>
  <div class="detail">
    <div class="card-box">
      <div class="title">
        <span>报告单号：</span>
        <span>{{ bgdh }}</span>
      </div>
      <div class="row">
        <div class="left">
          <span>姓名：</span>
          <span>{{ data.xm }}</span>
        </div>
        <div>
          <span>性别：</span>
          <span>{{ data.xb }}</span>
        </div>
      </div>
      <div class="row">
        <div class="left">
          <span>年龄：</span>
          <span>{{ data.age }}</span>
        </div>
        <div>
          <span>科室：</span>
          <span>{{ data.kb }}</span>
        </div>
      </div>
      <div class="row">
        <span>就诊卡号：</span>
        <span>{{ data.jzkh }}</span>
      </div>
      <div class="row">
        <span>样本种类：</span>
        <span>{{ data.bbzl }}</span>
      </div>
      <div class="row">
        <span>临床诊断：</span>
        <span>{{ data.lczd }}</span>
      </div>
      <div class="row">
        <span>采样日期：</span>
        <span>{{ data.cyrq }}</span>
      </div>
      <div class="row">
        <span>发布日期：</span>
        <span />
      </div>
      <div class="row">
        <div class="more" @click="routeTo()">
          查看更多
        </div>
      </div>
    </div>
    <div class="card-box">
      <div class="title">
        检验结论
      </div>
      <table width="100%">
        <tbody>
          <tr>
            <th>{{ headObj.th1 }}</th>
            <th>{{ headObj.th2 }}</th>
            <th>{{ headObj.th3 }}</th>
          </tr>
          <tr v-for="item in data.listJyReport" :key="item.id">
            <td>{{ item.jyzbxmmc }}<br>{{ item.jgdw }}</td>
            <td>
              <span :class="[item.arrowsTag == 1? 'down': '',item.arrowsTag == 2 ? 'up': '']">{{ item.jyjg }}&nbsp;{{ item.arrowsTag ==1?'↓': item.arrowsTag == 2?'↑':'' }}</span>
            </td>
            <td>{{ item.jgckzdx }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import {patientModel} from "../../../api/patient"

export default {
  name: 'CheckoutDetail',
  data() {
    return {
      data:{},
      bgdh:this.$route.query.bgdh,
      // patid:this.$route.query.patid,
      patxm:this.$route.query.patxm,
      zjh:this.$route.query.zjh,
      // list2:[
      //     {
      //         item:'白细胞',
      //         conc:'6.3',
      //         renf:'3.5-9.5'
      //     },
      //     {
      //         item:'平均血红蛋白浓度',
      //         conc:'353',
      //         renf:'316-354'
      //     }
      // ],
      headObj:{
        th1:'项目/单位',
        th2:'结果',
        th3: '参考值'
      }
    }
  },
  mounted(){
    this.loadData()
  },
  
  methods:{
    routeTo(){
      this.$router.push('/checkoutmore?patxm='+this.patxm+'&zjh='+this.zjh+'&bgdh='+this.bgdh)
    },
    loadData(){
      let params={
        // patid: this.$route.query.patid ||'',
        bgdh: this.$route.query.bgdh ||'',
        patxm: this.$route.query.patxm ||'',
        zjh: this.$route.query.zjh ||''
      }
      patientModel.jyReportDetail(params).then(res=>{
        this.data = res.data
      })
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../../assets/styles/variable.less";
span{
  &.up {
    color: #ff0000;
  }
  &.down {
    color: #0000ff;
  }
}
.detail {
    padding: 32px;

    .card-box {
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        margin-bottom: 24px;
        padding: 32px;
        box-sizing: border-box;

        table th {
            height: 68px;
            min-width: 140px;
            vertical-align: middle;
            border: 1px solid #DDDDDD;
            position: relative;
            text-align: center;
            color: #3D3D3D;
            font-size: 24px;
            background-color: #FFFFFF;
        }

        table td{
            padding: 40px 10px;
            text-align: center;
            color: #333333;
            font-size: 24px;
            border: 1px solid #DDDDDD;
            background-color: #FFFFFF;
        }

        .title {
            font-size: 32px;
            font-weight: bold;
            color: #3D3D3D;
            line-height: 45px;
            margin-bottom: 32px;
        }
        
        .row {
            display: flex;
            line-height: 40px;
            font-size: 28px;
            margin-bottom: 24px;
            color: #666666;

            .left {
                text-align: left;
                width: 325px;
            }

            .more {
                width: 622px;
                height: 48px;
                border-radius: 24px;
                border: 1px solid #3A8AE5;
                text-align: center;
                color: #3A8AE5;
                line-height: 50px;
                font-size: 24px;
            }
        }
    }
}
</style>
